import { months } from 'constants/date';
import moment from 'moment';

import {
    SET_REPRODUCTION_DATA,
    SET_REPRODUCTION_FILTER_OPTIONS,
    SET_REPRODUCTION_FILTERS,
    SET_REPRODUCTION_DATA_LOADING,
    RESET_REPRODUCTION_FILTERS,
    SET_REPRODUCTION_PROPERTY_ID
} from '@/redux/mutations';

const typeAttempsFilter = ['Cobertura', 'Inseminacao', 'TransferenciaDeEmbriao'];
const typesConditionIaFilter = ['Cio Natural', 'Prostaglandina', 'Protocolo IATF'];

export const initialState = {
    property_id: null,
    data: {},
    loading: true,
    optionsFilters: {
        years: [],
        attemptOrders: [],
        inseminators: [],
        bulls: [],
        calvingOrders: [],
        months: months,
        typeAttemps: typeAttempsFilter,
        typesConditionIa: typesConditionIaFilter
    },
    selectedFilters: {
        years: [moment().year()],
        cowOrHeifer: 'Todos',
        months: months,
        typeAttemps: typeAttempsFilter,
        attemptOrder: 'Todos',
        inseminator: 'Todos',
        bull: 'Todos',
        calvingOrder: [],
        typeConditionIa: typesConditionIaFilter,
        allmonths: true
    }
};

const indicatorsPanelReproductionReducer = (state = initialState, actions) => {
    switch (actions.type) {
        case SET_REPRODUCTION_PROPERTY_ID:
            return {
                ...state,
                property_id: actions.payload
            };
        case SET_REPRODUCTION_DATA:
            return {
                ...state,
                data: actions.payload
            };
        case SET_REPRODUCTION_DATA_LOADING:
            return {
                ...state,
                loading: actions.payload
            };
        case SET_REPRODUCTION_FILTER_OPTIONS:
            return {
                ...state,
                optionsFilters: actions.payload
            };
        case SET_REPRODUCTION_FILTERS:
            return {
                ...state,
                selectedFilters: actions.payload
            };
        case RESET_REPRODUCTION_FILTERS:
            return {
                ...state,
                selectedFilters: {
                    years: [moment().year()],
                    cowOrHeifer: 'Todos',
                    months: months,
                    typeAttemps: typeAttempsFilter,
                    attemptOrder: 'Todos',
                    inseminator: 'Todos',
                    bull: 'Todos',
                    calvingOrder: [],
                    typeConditionIa: typesConditionIaFilter
                }
            };
        default:
            return state;
    }
};

export default indicatorsPanelReproductionReducer;
