import React from 'react';

import EnterprisePage from '@/pages/Common/EnterpriseDetails/EnterprisePage';
import Events from '@/pages/Common/Events';
import ViewEvent from '@/pages/Common/Events/ViewEvent';
import Glossary from '@/pages/Common/Glossary';
import Watch from '@/pages/Common/Glossary/Watch';
import Notifications from '@/pages/Common/Notifications';
import DashboardLayout from '@components/templates/layouts/Outlet/DashboardLayout';
const Painel = React.lazy(() => import('pages/Admin/Painel'));
const EnterpriseDetails = React.lazy(() => import('pages/Common/EnterpriseDetails'));

const SectorQuestions = React.lazy(() => import('pages/Admin/CheckAgro/SectorQuestions'));
const NewQuestion = React.lazy(() => import('pages/Admin/CheckAgro/NewQuestion'));

const SectorQuestionsV2 = React.lazy(() => import('pages/Admin/CheckAgrov2/SectorQuestions'));
const NewQuestionV2 = React.lazy(() => import('pages/Admin/CheckAgrov2/NewQuestion'));
const Error404 = React.lazy(() => import('pages/Errors/404'));

const AssistentRoutes = {
    path: '/assistente',
    element: <DashboardLayout />,
    children: [
        {
            path: '/assistente/painel/:mainTab?',
            element: <Painel />
        },
        {
            path: '/assistente/empresa/:tabValue?/:id?/:tab?',
            element: <EnterpriseDetails />
        },
        {
            path: '/assistente/check-agro/setor/:sector_id/questoes',
            element: <SectorQuestions />
        },
        {
            path: '/assistente/check-agro/setor/:sector_id/questoes/nova-questao',
            element: <NewQuestion />
        },
        {
            path: `/assistente/check-agro/setor/:sector_id/questoes/editar-questao/:questionId`,
            element: <NewQuestion />
        },
        {
            path: '/assistente/check-agrov2/setor/:sector_id/questoes',
            element: <SectorQuestionsV2 />
        },
        {
            path: '/assistente/check-agrov2/setor/:sector_id/questoes/nova-questao',
            element: <NewQuestionV2 />
        },
        {
            path: `/assistente/check-agrov2/setor/:sector_id/questoes/editar-questao/:questionId`,
            element: <NewQuestionV2 />
        },
        {
            path: '/assistente/glossario',
            element: <Glossary />
        },
        {
            path: '/assistente/glossario/assistir/:id',
            element: <Watch />
        },
        {
            path: '*',
            element: <Error404 />
        },
        {
            path: '/assistente/encontros-ao-vivo',
            element: <Events />
        },
        {
            path: '/assistente/encontros-ao-vivo/:id',
            element: <ViewEvent />
        },
        {
            path: '/assistente/notificacoes',
            element: <Notifications />
        }
    ]
};
export default AssistentRoutes;
