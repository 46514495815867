import { isMoment } from 'moment';

export const formikFormData = (object, formData = new FormData(), parentKey = '') => {
    if (object === null || typeof object === 'undefined') {
        return formData;
    }

    Object.keys(object).forEach((key) => {
        const value = object[key];
        const fullKey = parentKey ? `${parentKey}[${key}]` : key;

        if (isMoment(value)) {
            formData.append(fullKey, value);
        } else if (typeof value === 'object' && !Array.isArray(value)) {
            formikFormData(value, formData, fullKey);
        } else if (Array.isArray(value) && value.every((item) => item instanceof Blob)) {
            value.forEach((item, index) => {
                formData.append(`${fullKey}[${index}]`, item, item.name);
            });
        } else if (Array.isArray(value)) {
            value.forEach((item, index) => {
                const itemKey = `${fullKey}[${index}]`;
                formikFormData(item, formData, itemKey);
            });
        } else if (typeof value === 'boolean') {
            formData.append(fullKey, value ? 1 : 0);
        } else {
            formData.append(fullKey, value);
        }
    });

    return formData;
};

export const formikFormDataWithFiles = (object, formData = new FormData(), parentKey = '') => {
    if (object === null || typeof object === 'undefined') {
        return formData;
    }

    Object.entries(object).forEach(([key, value]) => {
        const fullKey = parentKey ? `${parentKey}[${key}]` : key;

        if (value instanceof Blob || value instanceof File) {
            // Se for um arquivo (Blob/File), adiciona diretamente
            formData.append(fullKey, value, value.name);
        } else if (Array.isArray(value)) {
            // Se for um array
            value.forEach((item, index) => {
                const itemKey = `${fullKey}[${index}]`;

                if (item instanceof Blob || item instanceof File) {
                    // Se for um array de arquivos
                    formData.append(itemKey, item, item.name);
                } else {
                    formikFormData(item, formData, itemKey);
                }
            });
        } else if (typeof value === 'object' && value !== null) {
            // Se for um objeto, faz a recursão para transformar em FormData
            formikFormData(value, formData, fullKey);
        } else if (typeof value === 'boolean') {
            // Converte booleanos para 1 e 0
            formData.append(fullKey, value ? '1' : '0');
        } else if (value !== undefined && value !== null) {
            // Adiciona valores normais (string, número, etc.)
            formData.append(fullKey, value);
        }
    });

    return formData;
};
