import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import PropTypes from 'prop-types';

import { Close } from '@mui/icons-material';
import { Tooltip, Typography } from '@mui/material';

import useSnackbar from '../../../../hooks/useSnackbar';
import { Dropzone, Files, File, DropzoneText, FileDeleteButton } from './styles';

const DropzoneInput = ({
    dropText = 'Arraste arquivos para cá ou clique para upload',
    onDrop,
    error,
    errorMessage,
    maxFileSize,
    disabled,
    readFiles,
    handleDeleteFiles,
    multiple = true,
    value
}) => {
    const [files, setFiles] = useState(value ? [{ preview: value }] : []);

    const snackbar = useSnackbar();

    const { getRootProps, getInputProps } = useDropzone({
        disabled,
        accept: {
            'image/jpeg': [],
            'image/png': []
        },
        maxSize: maxFileSize * 1000000,
        onDrop: (acceptedFiles, fileRejections) => {
            setFiles([
                ...(multiple ? files : []),
                ...acceptedFiles.map((file) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file)
                    })
                )
            ]);
            if (fileRejections.length > 0) {
                if (fileRejections[0].errors[0].code === 'file-too-large') {
                    snackbar(`O arquivo não deve ser maior que ${maxFileSize}MB`, 'error');
                }
            }
        }
    });
    const handleDeleteFile = (toDelete) => (evt) => {
        if (!disabled) {
            evt.stopPropagation();
            if (toDelete.id) {
                handleDeleteFiles(toDelete);
            }
            setFiles(files.filter((file) => file.preview !== toDelete.preview));
        }
    };

    useEffect(() => {
        onDrop(files);
    }, [files]);

    // Load files on editing module
    useEffect(() => {
        if (readFiles) {
            const readOnlyFiles = readFiles.map((file) => ({
                ...file,
                preview: file.url
            }));
            setFiles(readOnlyFiles);
        }
    }, [readFiles]);

    return (
        <>
            <Dropzone {...getRootProps({ className: 'dropzone' })} error={error}>
                <input {...getInputProps()} />
                <DropzoneText>{dropText}</DropzoneText>
                {files.length > 0 && (
                    <Files>
                        {files.map((file, key) => (
                            <File key={`file-${key}`} src={file.preview}>
                                <FileDeleteButton type="button" variant={'contained'} size={'small'} onClick={handleDeleteFile(file)}>
                                    <Close size={'inherit'} sx={{ width: '15px', margin: 0 }} />
                                </FileDeleteButton>
                                <img src={file.preview} alt={'pré visualização'} />
                            </File>
                        ))}
                    </Files>
                )}
            </Dropzone>
            {error && (
                <Typography variant={'caption'} color={'error'}>
                    {errorMessage ?? 'Erro, insira um arquivo'}
                </Typography>
            )}
        </>
    );
};

DropzoneInput.propTypes = {
    disabled: PropTypes.bool,
    values: PropTypes.object,
    dropText: PropTypes.string,
    onDrop: PropTypes.func.isRequired,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    maxFileSize: PropTypes.number,
    readFiles: PropTypes.array
};

export default DropzoneInput;
