import React, { useState } from 'react';

import { HeaderStyled, ButtonMenu } from './styles';
const HeaderOptions = ({ optionSelected, setOptionSelected, options, paddingLeft = '0px', backgroundColor, gap }) => {
    console.log('optionSelected', optionSelected);
    // console.log('options', options')
    return (
        <HeaderStyled paddingLeft={paddingLeft} gap={gap}>
            {options?.map((value, index) => (
                <ButtonMenu
                    key={index}
                    onClick={() => setOptionSelected(value)}
                    style={
                        optionSelected !== value
                            ? { borderRadius: '8px 8px 0px 0px', backgroundColor: '#EBEFED', textTransform: 'none' }
                            : {
                                  borderRadius: '8px 8px 0px 0px',
                                  backgroundColor: backgroundColor ?? '#3A6647',
                                  color: '#ffffff',
                                  fontWeight: 600,
                                  textTransform: 'none'
                              }
                    }
                >
                    <h3>{value}</h3>
                </ButtonMenu>
            ))}
        </HeaderStyled>
    );
};

export default HeaderOptions;
