// Authentication
export const SET_LOGIN = 'SET_LOGIN';
export const SET_LOGOUT = 'SET_LOGOUT';
export const SET_AUTH_USER = 'SET_AUTH_USER';
export const SET_CURRENT_USER_ROLE = 'SET_CURRENT_USER_ROLE';

// Sidebar
export const DASHBOARD_SET_LEFT_SIDEBAR_EXPANDED = 'DASHBOARD_SET_LEFT_SIDEBAR_EXPANDED';
export const DASHBOARD_SET_RIGHT_SIDEBAR_CONTENT = 'DASHBOARD_SET_RIGHT_SIDEBAR_CONTENT';
export const DASHBOARD_OPEN_RIGHT_SIDEBAR = 'DASHBOARD_OPEN_RIGHT_SIDEBAR';
export const DASHBOARD_CLOSE_RIGHT_SIDEBAR = 'DASHBOARD_CLOSE_RIGHT_SIDEBAR';
export const DASHBOARD_OPEN_SETTINGS_MODAL = 'DASHBOARD_OPEN_SETTINGS_MODAL';
export const DASHBOARD_CLOSE_SETTINGS_MODAL = 'DASHBOARD_CLOSE_SETTINGS_MODAL';
export const DASHBOARD_NAVBAR_SHOW = 'DASHBOARD_NAVBAR_SHOW';
export const DASHBOARD_ACTIVE_USER = 'DASHBOARD_ACTIVE_USER';

export const SET_SEARCH = 'SET_SEARCH';

// ENTERPRISES
export const SET_ENTERPRISES = 'SET_COMPANIES';
export const SET_ENTERPRISE = 'SET_ENTERPRISE';
export const SET_EMPLOYEES = 'SET_EMPLOYEES';
export const SET_ENTERPRISE_SELECTED_NEW_EMPLOYEE = 'SET_ENTERPRISE_SELECTED_NEW_EMPLOYEE';
export const SET_ENTERPRISE_SELECTED_EMPLOYEE = 'SET_ENTERPRISE_SELECTED_EMPLOYEE';
export const SET_ENTERPRISE_BLOCK_EMPLOYEE_MODAL = 'SET_ENTERPRISE_BLOCK_EMPLOYEE_MODAL';
export const SET_ENTERPRISE_DETAILS_EMPLOYEE_MODAL = 'SET_ENTERPRISE_DETAILS_EMPLOYEE_MODAL';
export const SET_ENTERPRISE_EMPLOYEES_ARCHIVED_MODAL = 'SET_ENTERPRISE_EMPLOYEES_ARCHIVED_MODAL';

// PROPERTY

export const TOGGLE_ACTION_PLAN_DRAWER = 'TOGGLE_ACTION_PLAN_DRAWER';
export const TOGGLE_ACTION_PLAN_REGISTER = 'TOGGLE_ACTION_PLAN_REGISTER';

// User Events
export const SET_USER_EVENTS = 'SET_USER_EVENTS';
export const SET_USER_EVENTS_LOADING = 'SET_USER_EVENTS_LOADING';

// User Properties
export const SET_USER_PROPERTIES = 'SET_USER_PROPERTIES';

// User Actions Plans
export const SET_USER_ACTION_PLANS = 'SET_USER_ACTION_PLANS';
export const SET_USER_ACTION_PLANS_LOADING = 'SET_USER_ACTION_PLANS_LOADING';
export const SET_CHANGE_HOLDER_PROPERTY_MODAL = 'SET_CHANGE_HOLDER_PROPERTY_MODAL';
export const SET_USER_ARCHIVE_ACTION_PLANS = 'SET_USER_ARCHIVE_ACTION_PLANS';

// Indicators Panel
export const SET_MILKING_PROPERTY_ID = 'SET_MILKING_PROPERTY_ID';
export const SET_MILKING_CONTROL_DATA = 'SET_MILKING_CONTROL_DATA';
export const SET_MILKING_CONTROL_DATA_LOADING = 'SET_MILKING_CONTROL_DATA_LOADING';
export const SET_MILKING_CONTROL_FILTER_OPTIONS = 'SET_MILKING_CONTROL_FILTER_OPTIONS';
export const SET_MILKING_CONTROL_FILTERS = 'SET_MILKING_CONTROL_FILTERS';
export const RESET_MILKING_CONTROL_FILTERS = 'RESET_MILKING_CONTROL_FILTERS';

// Indicators Panel Subclinical Mastitis
export const SET_SUBCLINICAL_MASTITIS_PROPERTY_ID = 'SET_SUBCLINICAL_MASTITIS_PROPERTY_ID';
export const SET_SUBCLINICAL_MASTITIS_DATA = 'SET_SUBCLINICAL_MASTITIS_DATA';
export const SET_SUBCLINICAL_MASTITIS_DATA_LOADING = 'SET_SUBCLINICAL_MASTITIS_DATA_LOADING';
export const SET_SUBCLINICAL_MASTITIS_FILTER_OPTIONS = 'SET_SUBCLINICAL_MASTITIS_FILTER_OPTIONS';
export const SET_SUBCLINICAL_MASTITIS_FILTERS = 'SET_SUBCLINICAL_MASTITIS_FILTERS';
export const RESET_SUBCLINICAL_MASTITIS_FILTERS = 'RESET_SUBCLINICAL_MASTITIS_FILTERS';

// Indicators Panel Clinical Mastitis
export const SET_CLINICAL_MASTITIS_PROPERTY_ID = 'SET_CLINICAL_MASTITIS_PROPERTY_ID';
export const SET_CLINICAL_MASTITIS_DATA = 'SET_CLINICAL_MASTITIS_DATA';
export const SET_CLINICAL_MASTITIS_DATA_LOADING = 'SET_CLINICAL_MASTITIS_DATA_LOADING';
export const SET_CLINICAL_MASTITIS_FILTER_OPTIONS = 'SET_CLINICAL_MASTITIS_FILTER_OPTIONS';
export const SET_CLINICAL_MASTITIS_FILTERS = 'SET_CLINICAL_MASTITIS_FILTERS';
export const RESET_CLINICAL_MASTITIS_FILTERS = 'RESET_CLINICAL_MASTITIS_FILTERS';
// Indicators Panel Replacement
export const SET_REPLACEMENT_PROPERTY_ID = 'SET_REPLACEMENT_PROPERTY_ID';
export const SET_REPLACEMENT_DATA = 'SET_REPLACEMENT_DATA';
export const SET_REPLACEMENT_DATA_LOADING = 'SET_REPLACEMENT_DATA_LOADING';
export const SET_REPLACEMENT_FILTER_OPTIONS = 'SET_REPLACEMENT_FILTER_OPTIONS';
export const SET_REPLACEMENT_FILTERS = 'SET_REPLACEMENT_FILTERS';
export const RESET_REPLACEMENT_FILTERS = 'RESET_REPLACEMENT_FILTERS';
// Indicators Panel Reproduction
export const SET_REPRODUCTION_PROPERTY_ID = 'SET_REPRODUCTION_PROPERTY_ID';
export const SET_REPRODUCTION_DATA = 'SET_REPRODUCTION_DATA';
export const SET_REPRODUCTION_DATA_LOADING = 'SET_REPRODUCTION_DATA_LOADING';
export const SET_REPRODUCTION_FILTER_OPTIONS = 'SET_REPRODUCTION_FILTER_OPTIONS';
export const SET_REPRODUCTION_FILTERS = 'SET_REPRODUCTION_FILTERS';
export const RESET_REPRODUCTION_FILTERS = 'RESET_REPRODUCTION_FILTERS';
// Indicators Milk Production
export const SET_MILK_PRODUCTION_PROPERTY_ID = 'SET_MILK_PRODUCTION_PROPERTY_ID';
export const SET_MILK_PRODUCTION_DATA = 'SET_MILK_PRODUCTION_DATA';
export const SET_MILK_PRODUCTION_DATA_LOADING = 'SET_MILK_PRODUCTION_DATA_LOADING';
export const SET_MILK_PRODUCTION_FILTER_OPTIONS = 'SET_MILK_PRODUCTION_FILTER_OPTIONS';
export const SET_MILK_PRODUCTION_FILTERS = 'SET_MILK_PRODUCTION_FILTERS';
export const RESET_MILK_PRODUCTION_FILTERS = 'RESET_MILK_PRODUCTION_FILTERS';

// Indicators Panel Replacement
export const SET_TANK_PROPERTY_ID = 'SET_TANK_PROPERTY_ID';
export const SET_TANK_DATA = 'SET_TANK_DATA';
export const SET_TANK_DATA_LOADING = 'SET_TANK_DATA_LOADING';
export const SET_TANK_FILTER_OPTIONS = 'SET_TANK_FILTER_OPTIONS';
export const SET_TANK_FILTERS = 'SET_TANK_FILTERS';
export const RESET_TANK_FILTERS = 'RESET_TANK_FILTERS';
