import styled from 'styled-components';

import Button from '@mui/material/Button';

export const HeaderStyled = styled.header`
    height: 21%;
    display: flex;
    align-items: end;
    justify-content: start;
    border-bottom: 1px solid #9ba1a9;
    width: 100%;
    gap: ${(props) => props.paddingLeft || '0.5rem'};
    padding-left: ${(props) => props.paddingLeft};
`;
export const ButtonMenu = styled(Button)`
    background-color: #fff;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    color: #9ba1a9;
    // width: 159px;
    height: 48px;
    // margin-left: 4px;
    // z-index: 1;

    &:hover {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
    }

    > h3 {
        margin-left: 2rem;
        margin-right: 2rem;
    }
`;
