import React from 'react';

import PropTypes from 'prop-types';

import { TitleStyled } from './styles';

const SectionTitle = ({ tituloString, marginHorizontal = '2.7rem', fontSize }) => {
    return (
        <TitleStyled marginHorizontal={marginHorizontal} fontSize={fontSize}>
            {tituloString}
        </TitleStyled>
    );
};

export default SectionTitle;

SectionTitle.propTypes = {
    tituloString: PropTypes.string,
    marginHorizontal: PropTypes.string
};
